@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?a9bknd');
  src:  url('fonts/icomoon.eot?a9bknd#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?a9bknd') format('truetype'),
    url('fonts/icomoon.woff?a9bknd') format('woff'),
    url('fonts/icomoon.svg?a9bknd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cog2:before {
  content: "\e90e";
}
.icon-home:before {
  content: "\e908";
}
.icon-pencil2:before {
  content: "\e907";
}
.icon-droplet:before {
  content: "\e90b";
}
.icon-camera:before {
  content: "\e90f";
}
.icon-video-camera:before {
  content: "\e914";
}
.icon-bullhorn:before {
  content: "\e91a";
}
.icon-connection:before {
  content: "\e91b";
}
.icon-mic:before {
  content: "\e91e";
}
.icon-books:before {
  content: "\e920";
}
.icon-price-tag:before {
  content: "\e935";
}
.icon-cart:before {
  content: "\e93a";
}
.icon-phone:before {
  content: "\e942";
}
.icon-location2:before {
  content: "\e948";
}
.icon-compass2:before {
  content: "\e94a";
}
.icon-map:before {
  content: "\e94b";
}
.icon-alarm:before {
  content: "\e950";
}
.icon-bell:before {
  content: "\e951";
}
.icon-database:before {
  content: "\e964";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-cog:before {
  content: "\e994";
}
.icon-cogs2:before {
  content: "\e995";
}
.icon-hammer:before {
  content: "\e996";
}
.icon-stats-bars2:before {
  content: "\e99d";
}
.icon-lab:before {
  content: "\e9aa";
}
.icon-airplane:before {
  content: "\e9af";
}
.icon-truck:before {
  content: "\e9b0";
}
.icon-shield:before {
  content: "\e9b4";
}
.icon-cloud-upload:before {
  content: "\e9c3";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-bookmark:before {
  content: "\e9d2";
}
.icon-bookmarks:before {
  content: "\e9d3";
}
.icon-heart:before {
  content: "\e9da";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-mail4:before {
  content: "\ea86";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-telegram:before {
  content: "\ea95";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-vk:before {
  content: "\ea98";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-youtube2:before {
  content: "\ea9e";
}
.icon-appleinc:before {
  content: "\eabe";
}
.icon-android:before {
  content: "\eac0";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-settings:before {
  content: "\e90d";
}
.icon-key:before {
  content: "\e91c";
}
.icon-clipboard-edit:before {
  content: "\e910";
}
.icon-document-edit:before {
  content: "\e911";
}
.icon-tag-cord:before {
  content: "\e912";
}
.icon-tag-cord1:before {
  content: "\e913";
}
.icon-stop-watch:before {
  content: "\e915";
}
.icon-award:before {
  content: "\e916";
}
.icon-envelope-o:before {
  content: "\f003";
}
.icon-user:before {
  content: "\f007";
}
.icon-flag:before {
  content: "\f024";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-calendar:before {
  content: "\f073";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-cogs:before {
  content: "\f085";
}
.icon-gears:before {
  content: "\f085";
}
.icon-thumb-tack:before {
  content: "\f08d";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-comment-o:before {
  content: "\f0e5";
}
.icon-flag-o:before {
  content: "\f11d";
}
.icon-flag-checkered:before {
  content: "\f11e";
}
.icon-dribbble:before {
  content: "\f17d";
}
.icon-envelope-square:before {
  content: "\f199";
}
.icon-cubes:before {
  content: "\f1b3";
}
.icon-file-powerpoint-o:before {
  content: "\f1c4";
}
.icon-wechat:before {
  content: "\f1d7";
}
.icon-weixin:before {
  content: "\f1d7";
}
.icon-connectdevelop:before {
  content: "\f20e";
}
.icon-shopping-basket:before {
  content: "\f291";
}
.icon-envelope-open:before {
  content: "\f2b6";
}
.icon-envelope-open-o:before {
  content: "\f2b7";
}
.icon-bath:before {
  content: "\f2cd";
}
.icon-bathtub:before {
  content: "\f2cd";
}
.icon-s15:before {
  content: "\f2cd";
}
.icon-add-outline:before {
  content: "\e909";
}
.icon-add-solid:before {
  content: "\e90a";
}
.icon-anchor:before {
  content: "\e91d";
}
.icon-chart:before {
  content: "\e917";
}
.icon-location-gas-station:before {
  content: "\e91f";
}
.icon-menu:before {
  content: "\e90c";
}
.icon-portfolio:before {
  content: "\e918";
}
.icon-timer:before {
  content: "\e919";
}
.icon-wine-bottlew:before {
  content: "\e900";
}
.icon-wine-bottle:before {
  content: "\e901";
}
.icon-ice-bucket-and-bottle:before {
  content: "\e902";
}
.icon-glass-with-wine:before {
  content: "\e903";
}
.icon-glass-and-bottle-of-wine:before {
  content: "\e904";
}
.icon-wine-list:before {
  content: "\e905";
}
.icon-bunch-of-grapes:before {
  content: "\e906";
}
